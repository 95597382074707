

.emoticon-01{
    background-image: url(../../img/emo/01.gif);
}
.emoticon-02{
    background-image: url(../../img/emo/02.gif);
}
.emoticon-03{
    background-image: url(../../img/emo/03.gif);
}
.emoticon-04{
    background-image: url(../../img/emo/04.gif);
}
.emoticon-05{
    background-image: url(../../img/emo/05.gif);
}
.emoticon-06{
    background-image: url(../../img/emo/06.gif);
}
.emoticon-07{
    background-image: url(../../img/emo/07.gif);
}
.emoticon-08{
    background-image: url(../../img/emo/08.gif);
}
.emoticon-09{
    background-image: url(../../img/emo/09.gif);
}
.emoticon-10{
    background-image: url(../../img/emo/10.gif);
}
.emoticon-11{
    background-image: url(../../img/emo/11.gif);
}
.emoticon-12{
    background-image: url(../../img/emo/12.gif);
}
.emoticon-13{
    background-image: url(../../img/emo/13.gif);
}
.emoticon-14{
    background-image: url(../../img/emo/14.gif);
}
.emoticon-15{
    background-image: url(../../img/emo/15.gif);
}
.emoticon-16{
    background-image: url(../../img/emo/16.gif);
}
.emoticon-17{
    background-image: url(../../img/emo/17.gif);
}
.emoticon-18{
    background-image: url(../../img/emo/18.gif);
}
.emoticon-19{
    background-image: url(../../img/emo/19.gif);
}
