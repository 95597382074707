html, body, #root{
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background: #e0e0e0;
    /*TODO: odkomentovat ... overflow: hidden;*/
}


* {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}