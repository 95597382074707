.App {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  min-height: 200px;
  background: white;
}
.tester {
  background: #818181;
  position: relative;
  flex-grow: 2;
  padding: 10px;
}
.tester .tcontent {
  color: white;
  padding: 10px;
}
.tester.speak .tcontent:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 0;
  height: 0;
  z-index: 1;
  border: 15px solid transparent;
  border-top-color: #818181;
  border-bottom: 0;
  margin-left: -15px;
  margin-bottom: -15px;
}
.tester.mind .tcontent:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 15px;
  height: 15px;
  z-index: 1;
  border: 0;
  background: #818181;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: -16px;
}
.tester.mind .tcontent:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 10px;
  height: 10px;
  z-index: 1;
  border: 0;
  background: #818181;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-left: 8px;
  margin-bottom: -26px;
}
.stress-box .timer {
  position: relative;
  height: 15px;
}
.stress-box .timer .stressline {
  position: absolute;
  top: 5px;
  right: 0;
  background: #818181;
  height: 5px;
  transition: width 1s linear 0s, background-color 0.2s ease 0s;
}
.stress-box .timer .stressline.red {
  background: #f40029;
}
.stress-box .timer .stressline.red-alert {
  -webkit-animation: stressanimation 0.5s infinite;
  /* Safari 4+ */
  -moz-animation: stressanimation 0.5s infinite;
  /* Fx 5+ */
  -o-animation: stressanimation 0.5s infinite;
  /* Opera 12+ */
  animation: stressanimation 0.5s infinite;
  /* IE 10+, Fx 29+ */
}
@keyframes stressanimation {
  0%,
  49% {
    background: #818181;
  }
  50%,
  100% {
    background: #f40029;
  }
}
.App.frame {
  padding: 0;
  border: 10px solid white;
}
.App.frame.red {
  transition: border 0.2s linear 0s;
  border-color: #f40029;
}
.App.frame.red-alert {
  transition: border 0.2s linear 0s;
  -webkit-animation: frameanimation 0.5s infinite;
  /* Safari 4+ */
  -moz-animation: frameanimation 0.5s infinite;
  /* Fx 5+ */
  -o-animation: frameanimation 0.5s infinite;
  /* Opera 12+ */
  animation: frameanimation 0.5s infinite;
  /* IE 10+, Fx 29+ */
}
@keyframes frameanimation {
  0%,
  49% {
    border-color: white;
  }
  50%,
  100% {
    border-color: #f40029;
  }
}
.emoticon-box {
  flex-grow: 2;
}
.answers {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.answers .active-answer input {
  padding: 20px 10px;
  font-size: 26px;
  width: 100%;
}
.answers .answer {
  background-color: #fff;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
}
.answers .answer:hover {
  opacity: 0.9;
}
.answers .answer.inactive {
  opacity: 0.3;
}
.answers .answer.inactive .acontent {
  opacity: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.answers .answer.active {
  opacity: 1;
}
.answers .answer.active:hover {
  opacity: 1;
}
.answers .answer .acontent {
  padding: 10px;
  height: 100%;
  min-height: 57px;
}
.answers .answer.acolor1 {
  color: black;
  background: #e79abc;
  /*rgb(250,223,235);*/
}
.answers .answer.acolor2 {
  color: black;
  background: #cde39b;
  /*rgb(236,245,215);*/
}
.answers .answer.acolor3 {
  color: black;
  background: #7DC7FA;
  /*rgb(217,236,249);*/
}
.answers .answer.acolor4 {
  color: black;
  background: #fffeb9;
  /*rgb(255,254,185);*/
}
.stopcode {
  text-align: center;
}
.stopcode .message {
  font-size: 26px;
  padding: 20px 20px 60px 20px;
}
.stopcode .controls button {
  width: 100px;
  padding: 2px;
  cursor: pointer;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 50vh;
  text-align: center;
  background: rgba(204, 204, 204, 0);
}
.loading .text {
  padding-bottom: 60px;
  font-size: 20px;
  color: black;
}
.loading .lds-default {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading .lds-default div {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #dddddd;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.loading .lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}
.loading .lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}
.loading .lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}
.loading .lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}
.loading .lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}
.loading .lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}
.loading .lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}
.loading .lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}
.loading .lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}
.loading .lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}
.loading .lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}
.loading .lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.App.login {
  background-position: center center;
  background-repeat: no-repeat;
}
.App .login {
  margin-top: 50vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.App .login header {
  font-size: 28px;
  color: white;
}
.App .login label span {
  color: black;
  display: block;
  width: 80px;
}
.App .login label input {
  padding: 3px 8px;
  font-size: 18px;
}
.App .login button {
  cursor: pointer;
  font-size: 17px;
}
.finalaction a {
  cursor: pointer;
  text-decoration: underline;
}
.finalaction a:hover {
  text-decoration: none;
}
.speech {
  display: block;
  width: 20px;
  height: 20px;
  top: 50%;
  margin-top: -12px;
  right: 10px;
  position: absolute;
  z-index: 100;
  border: 0;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent transparent transparent #646464;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 12px 0 12px 20px;
}
.tester .speech {
  top: 100%;
  margin-top: -35px;
  border-left-color: white;
}
.aco {
  background: #7884b3;
  color: black!important;
  resize: none;
  width: 100%;
  height: 70px;
  margin: 0;
  padding: 8px;
  border: 1px solid #0e0d33;
  outline: none;
  font-size: 20px;
}
.aco:disabled {
  opacity: 0.7;
  background: #0e0d33;
  color: #dddddd !important;
}
/*
.aco::placeholder, .aco::-ms-input-placeholder, .aco::-ms-input-placeholder  {
  color: red!important;
  opacity: 1;
}
*/
