
.App .login{

    margin-top:184px;
    background: transparent;
    text-align: center;

}
.App .login .loginenter{
    margin-top:30px;
}
.App .login label input{
    background: transparent;
    border:0;
    border-bottom: 5px solid white;
    padding: 8px ;
    color:white;
    font-size: 20px;
    outline:0;
}
.App .login button{
    font-size:20px;
    font-weight: 600;
}
.App.frame{
    border-width: 0;
}
.App.frame.red{
    border-width: 10px;
}
.emoticon-box {
    /*background-image: url(img/smile.png);*/
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-clip: border-box;
    height: 120px;
    -webkit-box-flex: unset;
    -ms-flex-positive: unset;
    flex-grow: unset;
    flex:none;
}

.answers, .tester, .emoticon-box, .stress-box {
    margin: 0;
}

.stress-box{
    padding: 10px 20px;
    height:  50px;
}
.stress-box .timer .stressline{
    background-color: #ffcd50;
    height: 12px;
}

@keyframes stressanimation {
    0%,
    49% {
        background: #ffcd50;
    }
    50%,
    100% {
        background: #f40029;
    }
}
.answers{
    margin-top: 20px;
}

.tpicidview .emoticon-box{
    text-align: center;
    height: unset; /*odstranit height = 100px*/
    margin: 0;  /*nové*/

}

.speaker {
    position: absolute;
    margin-left:10px;
    width: 30px !important;
    height: 100%;

    background:white;
    mask-image: url(img/repro-sharp.svg) !important;
    mask-size: 30px 25px;
    mask-repeat: no-repeat;
    mask-position: center center;
}

.speaker:hover {
    cursor: pointer;
}

.speaker--main {
    width: 30px !important;
    height: 25px;
    position: absolute;
    right: 12px;
    bottom: -140px;
    background:white;
    mask-image: url(img/repro-sharp.svg) !important;
    mask-size: 30px 25px;
    mask-repeat: no-repeat;
}

.speaker--main:hover {
    cursor: pointer;
}

.tester{
    font-size: 20px;
    padding: 30px 10px 20px 20px;
    flex-grow: unset;
}
.tester, .emoticon-box, .stress-box{
    background-color: transparent;
}
.tester.speak .tcontent:after{
    content: none;
}

/* tester design, font-size size answer */
.answers .answer{
    --btn-raise: 20px;
    clip-path: polygon(var(--btn-raise) 0%, calc(100% - var(--btn-raise)) 0%, 100% 50%, calc(100% - var(--btn-raise)) 100%, var(--btn-raise) 100%, 0 50%);

    color: rgb(0, 12, 13) !important;
    font-size: 16px;
    font-weight: 600;
}

.answers .answer .acontent{
    padding:10px 20px 10px 40px;
}

#root, .App, .answers{
    background-color: transparent;
}
html, body{

    background-color: rgb(0, 21, 23 );
    background-image:url('img/backgroundhex.png');
    background-repeat:no-repeat;
    background-position: center center;
    background-size: 500px 500px;
}
.App{
    max-width: 400px;

}
.tester{
    padding:20px;
    margin-top:10px;
    margin-bottom:20px;
}

.answer{
    margin-bottom: 8px;
}



.answers .answer{
    background-color:transparent!important;
    background-size: 100% 100%!important;
    background-repeat: no-repeat !important;

}
.answers .answer .acontent {

    padding-right: 70px !important;
    min-height: 70px;
}
.answers .answer.acolor1{
    background-image: url(img/odkaz-c.png);

}
.answers .answer.acolor1.active{
    background-image: url(img/odkaz-2-c.png);
}

.answers .answer.acolor2 {
    background-image: url(img/odkaz-b.png);
}

.answers .answer.acolor2.active  {
    background-image: url(img/odkaz-2-b.png);
}

.answers .answer.acolor3{
    background-image: url(img/odkaz-m.png);
}

.answers .answer.acolor3.active {
    background-image: url(img/odkaz-2-m.png);
}

.answers .answer.acolor4 {
    background-image: url(img/odkaz-z.png);
}

.answers .answer.acolor4.active {
    background-image: url(img/odkaz-2-z.png);
}
.answers .answer.inactive{
    opacity: 0;
}



.stopcode .message{
    padding-top:90px;
    color:white;
}
.stopcode .controls{
    color:white;
}

.stopcode .controls .timer{
    font-size: 30px;
}

.stopcode .controls button{
    width: 200px;
    font-size: 18px;
    height: 42px;
}

.stopcode .finalaction .btn-again{
color:white;
    font-size: 18px;
}